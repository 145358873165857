<template>
    <v-container fluid style="margin-bottom:100px">
        <v-row>
            <v-col cols="12">
                 <v-breadcrumbs :items="breadcumbs"></v-breadcrumbs>
                 <template v-slot:item="{ item }">
                 <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled" exact>
                 {{ item.text }}
                 </v-breadcrumbs-item>
                 </template>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col xs="12" sm="6" md="3" class="col-12">
                <h6 class="blue-lcd">Office</h6>
                <v-autocomplete
                    solo
                    v-model="office"
                    :items="offices"
                    item-value="office_id"
                    item-text="office_name"
                    label="Office"
                    @change="(event) => updateEmployee(event)"
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="6" md="2" class="col-12">
                <h6 class="blue-lcd">Department</h6>
                <v-autocomplete
                    solo
                    v-model="department"
                    :items="departments"
                    item-value="dept_id"
                    item-text="dept_name"
                    label="Department"
                    @change="(event) => updateDivisi(event)"
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="6" md="2" class="col-12">
                <h6 class="blue-lcd">Division</h6>
                <v-autocomplete
                    solo
                    v-model="division"
                    :items="divisions"
                    item-value="division_id"
                    item-text="division_name"
                    label="Division"
                    @change="(event) => updateEmployee(event)"
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="6" md="3" class="col-12">
                <h6 class="blue-lcd">Employee</h6>
                <v-autocomplete
                    solo
                    v-model="employee"
                    :items="employees"
                    item-value="idemployee"
                    :item-text="item => `${item.idemployee} - ${item.name}`"
                    label="Employee"
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col xs="12" sm="6" md="2" class="col-12">
                <h6 class="blue-lcd">Year</h6>
                <v-autocomplete
                    clearable
                    solo
                    class="ma-0 pa-0 border-12"
                    hide-details=true
                    v-model="year"
                    :items="years"
                    item-value="Tahun"
                    item-text="Tahun"
                    label="Year"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="mt-2" align="center" justify="center">
            <v-col cols="12" xs="12" sm="2" md="2">
                <v-btn block class="border-12  p-4" color="#f1f1f1" @click="clear()">Clear</v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="2" md="2">
                <v-btn block class="border-12 text-white p-4" color="primary" @click="getPullData(1,10)">Search</v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-8">
            <v-col cols="12">
                <v-card class="rounded-l bg_card" style="border-top: 3px solid #1565C0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        solo
                                        class="border-12"
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search ID Employee"
                                        single-line
                                        @keyup.enter="getPullData"
                                        hide-details
                                        style="max-width:30em"
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="itemLists"
                                    :options.sync="options"
                                    :loading="loadingDatatable"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`header.Tahun`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.idemployee`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.office_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dept_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.division_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.sisa_cuti`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`item.sisa_cuti`]="{ item }">
                                        <p v-if="parseInt(item.sisa_cuti) < 0 || item.sisa_cuti === '-.5'">
                                            0
                                        </p> 
                                        <p v-else>
                                            {{item.sisa_cuti}}
                                        </p>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail Annual</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>Annual
                                            <v-spacer></v-spacer>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_po_items"
                                            :items="po_items"
                                            :options.sync="option_po_items"
                                            :loading="loading3"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItem_po_items"
                                            :page.sync="pagination_item"
                                            page-count="10"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`header.entry_date`]="{ header }">
                                                <button @click="sortByFuncDetail(header.value)">{{header.text}}</button>
                                                <i v-if="sortByDetail === 'desc'" aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                            <template v-slot:[`header.idemployee`]="{ header }">
                                                <button @click="sortByFuncDetail(header.value)">{{header.text}}</button>
                                                <i v-if="sortByDetail === 'desc'" aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                            <template v-slot:[`header.name`]="{ header }">
                                                <button @click="sortByFuncDetail(header.value)">{{header.text}}</button>
                                                <i v-if="sortByDetail === 'desc'" aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                            <template v-slot:[`header.office_name`]="{ header }">
                                                <button @click="sortByFuncDetail(header.value)">{{header.text}}</button>
                                                <i v-if="sortByDetail === 'desc'" aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>   
</template>

<script>
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            items: ['coba', 'hello'],
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'HRIS',
                disabled: false,
                href: '#',
                },
                {
                text: 'Annual Leave Report',
                disabled: true,
                href: '#',
                },
            ],
            offices: [],
            office: '',
            departments: [],
            department: '',
            divisions: [],
            division: '',
            employees: [],
            employee: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            itemLists: [],
            headers: [
                {
                    text: 'Year',
                    align: 'start',
                    sortable: true,
                    value: 'Tahun',
                    class: 'primary--text blue lighten-5', sortable: false },
                {
                    text: 'ID Employee',
                    value: 'idemployee'
                , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Name Employee', value: 'name' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Office', value: 'office_name' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Department', value: 'dept_name' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Division', value: 'division_name' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'ALPHA', value: 'ALPHA' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'CUTI', value: 'CUTI' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'CUTI 1\/2 HARI', value: 'CUTI 1\/2 HARI' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN 1\/2 HARI', value: 'IJIN 1\/2 HARI' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN TIDAK MASUK', value: 'IJIN TIDAK MASUK' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN SAKIT', value: 'IJIN SAKIT' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Sisa Cuti', value: 'sisa_cuti' , class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Actions',  value: 'actions', sortable: false , class: 'primary--text blue lighten-5', sortable: false },
            ],
            loadingDatatable: false,
            searchItem: '',
            years: [],
            year: '',
            options:{},
            totalItems: 10,
            pagination: 1,
            display: 'none',
            sortBy: 'desc',
            sortName: '',
            sortByDetail: 'desc',
            sortNameDetail: '',
            dialog: false,
            po_items: [],
            po_item: null,
            pagination_item: 1,
            loading3: false,
            totalItem_po_items: 10,
            header_po_items:[
                {
                    text: 'Entry Date',
                    align: 'start',
                    sortable: true,
                    value: 'entry_date',
                    class: 'primary--text blue lighten-5', sortable: false
                },
                { text: 'ID Employee', value: 'idemployee', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Name Employee', value: 'name', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Office', value: 'office_name', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Time IN', value: 'time_in', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Time Out', value: 'time_out', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Department', value: 'dept_name', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Division', value: 'division_name', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'ALPHA', value: 'ALPHA', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'CUTI', value: 'CUTI', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'CUTI 1\/2 HARI', value: 'CUTI 1\/2 HARI', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN 1\/2 HARI', value: 'IJIN 1\/2 HARI', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN TIDAK MASUK', value: 'IJIN TIDAK MASUK', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN SAKIT', value: 'IJIN SAKIT', class: 'primary--text blue lighten-5', sortable: false }
            ],
            option_po_items: {},

        }
    },
    async mounted(){
        this.getOffice()
        this.getDepartment()
        this.getDivision()
        this.getEmployee()
        this.getYear()
    },
    methods:{
        async getYear(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/hris/year`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.years = res.data
            });
        },
        async getOffice(){
            
            const respData = await backendApi.fetchCore('/api/master/kka/hris/getOffice', null, false, false, false)

            if (respData.status === 200) {
                this.offices = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }                
        },
        async getDepartment(){
            
            const respData = await backendApi.fetchCore('/api/master/kka/hris/getDepartment', null, false, false, false)

            if (respData.status === 200) {
                this.departments = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }                
        },
        async getDivision(){
            
            const respData = await backendApi.fetchCore(`/api/master/kka/hris/getDivision?dept_id=${this.department ? this.department : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.divisions = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }                
        },
        async getEmployee(){
            const respData = await backendApi.fetchCore(`/api/master/kka/hris/getEmployee?office_id=${this.office ? this.office : ''}&dept_id=${this.department ? this.department : ''}&division_id=${this.division ? this.division : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.employees = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }      
        },
        async updateEmployee(value){
            this.getEmployee()
        },
        async updateDivisi(value){
            this.getDivision()
        },
        clear(){
            this.office = ''
            this.department = ''
            this.division = ''
            this.employee = ''
            this.year = ''
            this.$store.state.text = ''
        },
        sortByFunc(sortBy){
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.getPullData(1, 10)
        },
        async getPullData(page = 1, itemsPerPage = 10){
            this.loadingDatatable = true
            this.itemLists = []

            const respData = await backendApi.fetchCore(`/v2/kka/hris/annual?search=${this.$store.state.text}&txtOffID=${this.office ? this.office : ""}&txtDept=${this.department ? this.department : ""}&txtDiv=${this.division ? this.division : ""}&txtEmployee=${this.employee ? this.employee : ""}&txtYear=${this.year ? this.year : ""}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, null, false, false, false)

            if (respData.status === 200) {
                this.display = 'block'
                this.loadingDatatable = false
                this.itemLists = respData.data.data
                this.totalItems = respData.data.total
                this.pagination = respData.data.current_page
            } else {
                this.loadingDatatable = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }      
        },
        close() {
            this.dialog = false
        },
        showItem(item){
            this.po_item = item
            this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(1, 10)
        },
        sortByFuncDetail(sortBy){
            this.sortByDetail = this.sortByDetail === 'desc' ? 'asc' : 'desc';
            this.sortNameDetail = sortBy
            this.getPullDataDetail(1, 10)
        },
        async getPullDataDetail(page = 1, itemsPerPage = 10){
            this.loading3 = true
            this.po_items = []

            const respData = await backendApi.fetchCore(`/v2/kka/hris/annual/show?search=${this.search_detail}&txtEmployee=${this.po_item.idemployee}&txtYear=${this.po_item ? this.po_item.Tahun : ""}&sort=${this.sortByDetail ? this.sortByDetail : 'desc'}&sortName=${this.sortNameDetail ? this.sortNameDetail : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, null, false, false, false)

            if (respData.status === 200) {
                this.display = 'block'
                this.loading3 = false
                this.po_items = respData.data.data
                this.totalItem_po_items = respData.data.total
                this.pagination_item = respData.data.current_page
            } else {
                this.loading3 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }      
        },
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === 'block') {
                    this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        },
        option_po_items: {
            handler () {
                const { page, itemsPerPage } = this.option_po_items

                if (this.po_item && page >= 1) {
                    this.getPullDataDetail(page, itemsPerPage)
                }
            },
            deep: true,
        }
    }   
}
</script>